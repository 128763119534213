import 'react-phone-number-input/style.css';
import React, { useState, useRef } from 'react';
import PhoneInput, { parsePhoneNumber } from 'react-phone-number-input/input';
import logo from '../../assets/logo.png';
import api from '../../services/api';
import '../../styles.css'
import './styles.css'
import { DateTime } from 'luxon'



export default function Cancel() {
	const phone = useRef();
	const [bookings, setBookings] = useState([]);
	const [loading, setLoading] = useState(false);

	function handlePhoneChanged(value) {
		phone.current = value;
	}

	async function handleSubmit(e) {
		e.preventDefault();
		try {
			setLoading(true);
			const bookings = await api.get('/bookingsByPhone', { params: { phone: phone.current } });
			if (bookings.status === 204) {
				alert('Nenhum agendamento encontrado para este número.');
				setLoading(false);
				return setBookings([]);
			}
			setBookings(bookings.data);
		} catch (err) {
			alert('Erro ao buscar agendamentos. Por favor tente novamente mais tarde.');
		}
		setLoading(false);
	}

	async function cancelBooking(p) {
		try {
			const booking = await api.patch(`/bookingByPhone/${p}`, { accepted: false });
			const date = booking.data.date;
			alert(`Agendamento para o dia: ${DateTime.fromISO(date).toLocaleString(DateTime.DATETIME_SHORT)} cancelado com sucesso.`);
			window.location.reload(false);
		} catch (err) {
			alert('Erro ao cancelar agendamento, tente novamente mais tarde.')
			console.error(err);
		}
	}


	return (
		<div>
			<header>
				<div className='header-1'>
					<div className='logo'>
						<a href="/">
							<img src={logo} width='250px' alt='' />
						</a>
					</div>
				</div>
			</header>
			<main>
				<form action='' onSubmit={handleSubmit}>
					<h3>CANCELAR AGENDAMENTO</h3>
					<PhoneInput
						placeholder='Digite seu número de telefone'
						country='BR'
						onChange={handlePhoneChanged}
					/>
					<button type='submit' disabled={loading} style={loading ? { backgroundColor: '#a1e6c4', cursor: 'default' } : {}}>Ver meus agendamentos</button>
				</form>
				<ul>
					{bookings.map((b) => (
						<li key={b.id}>
							<p>Nome: {b.name}</p>
							<p>Telefone: {parsePhoneNumber(b.phone).format('NATIONAL')}</p>
							<p>Data: {DateTime.fromISO(b.date).toLocaleString(DateTime.DATETIME_SHORT)}</p>
							<div style={{ display: 'flex', justifyContent: 'center' }}>
								<button type='submit' onClick={() => cancelBooking(b.phone)}>Cancelar</button>
							</div>
						</li>
					))}
				</ul>
			</main>
		</div>
	);
};