import React from 'react';
import ReactDOM from 'react-dom';
import './styles.css';

import Booking from './pages/booking';
import AfterBooking from './pages/afterBooking';
import Cancel from './pages/cancel';
import History from './pages/history';

import {
	BrowserRouter as Router,
	Switch,
	Route,
} from 'react-router-dom';

ReactDOM.render(
	<React.StrictMode>
		<Router>
			<Switch>
				<Route path='/' exact component={Booking} />
				<Route path='/agendamento/:id' exact component={AfterBooking} />
				<Route path='/agendamentos' component={History} />
				<Route path='/cancelamento' component={Cancel} />
			</Switch>
		</Router>
	</React.StrictMode>,
	document.getElementById('root')
);
