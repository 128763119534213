import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import api from '../../services/api';
import '../../styles.css'
import logo from '../../assets/logo.png';

export default function AfterBooking() {
	const { id } = useParams();
	const [booking, setBooking] = useState({});
	useEffect(() => loadBooking())

	async function loadBooking() {
		if (booking.date) {
			return;
		}
		setBooking((await api.get(`/booking/${id}`)).data);
	}

	return (
		<div>
			<header>
				<div className="header-1">
					<div className="logo">
						<a href="/">
							<img src={logo} width='250px' alt='' />
						</a>
					</div>
				</div>
			</header>
			<main>
				<div style={{ backgroundColor: 'rgba(0, 0, 0, 0.7)', padding: '80px', borderRadius: 15 }}>
					<h1 style={{ color: '#fff' }}>Seu agendamento foi confirmado</h1>
					<h3 style={{ color: '#fff' }}>Você agendou para {booking.date && new Date(booking.date).toLocaleString()}</h3>
					<p style={{ color: '#fff' }}>Pedimos a gentileza de chegar 5 minutos antes! Em caso de atraso seu horário será cancelado.</p>
				</div>
			</main>
		</div>
	);
}